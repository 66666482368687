import { createAction, createReducer } from 'redux-act';

import { serviceError } from '../../../language/errors';

const initialState = {
    error: null,
    isLoginModalOpen: false,
    social: null,
};

export const login = createAction('authentication/login');
export const loginSuccess = createAction('authentication/loginSuccess');
export const loginFailure = createAction('authentication/loginFailure');

export const logout = createAction('authentication/logout');

export const loginBySocialAccount = createAction(
    'authentication/loginBySocialAccount',
);
export const loginBySocialAccountSuccess = createAction(
    'authentication/loginBySocialAccountSuccess',
);
export const loginBySocialAccountFailure = createAction(
    'authentication/loginBySocialAccountFailure',
);

export const openLoginModal = createAction('authentication/openLoginModal');
export const closeLoginModal = createAction('authentication/closeLoginModal');

const handleLoginSuccess = (state, payload) => {
    return {
        ...state,
        ...payload,
        error: null,
    };
};

const handleLoginFailure = (state, e) => {
    const error = e || {};

    return {
        ...state,
        error: {
            code: error.code,
            message: error.message || serviceError.title,
        },
    };
};

const handleLoginBySocialAccountFailure = (state, payload) => ({
    ...state,
    ...payload,
});

const handleOpenLoginModal = state => ({
    ...state,
    isLoginModalOpen: true,
});
const handleCloseLoginModal = state => ({
    ...state,
    isLoginModalOpen: false,
});

export default createReducer(
    {
        [closeLoginModal]: handleCloseLoginModal,
        [loginBySocialAccountFailure]: handleLoginBySocialAccountFailure,
        [loginFailure]: handleLoginFailure,
        [loginSuccess]: handleLoginSuccess,
        [openLoginModal]: handleOpenLoginModal,
    },
    initialState,
);

export * from './selectors';
